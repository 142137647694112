.loader {
  z-index: 99999;
  left: 0px;
  top: 0px;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.loader-groupe img {
  width: 200px;
  object-fit: contain;
}

.loader-groupe span {
  width: 200px !important;
}