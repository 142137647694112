.main .card {
  min-height: calc(100vh - 108px);
}

.main .card .btn {
  background-color: #1a327d;
  border: none;
  box-shadow: none;
}

@media screen and (max-width: 414px) {
  .main .card .btn {
    font-size: 12px;
  }
}

.main .card .btn:disabled {
  background-color: #92979e !important;
}

.main .form-control,
.main .form-select {
  background-color: #f9f9f9;
  border-color: #dee2e6;
  box-shadow: none;
  font-size: small;
  border-radius: 4px;
}

.main .card .print-table {
  max-height: calc(100vh - 60px - 40px - 3rem - 81.5px - 40px - 1rem);
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  border: 1px solid #1a327d;
}

.main .card .print-table .table-header {
  position: sticky;
  top: 0;
  background-color: #1a327d !important;
  color: #dee2e6;
}

.main .card .print-table .table-row {
  display: inline-flex;
  width: 100%;
  border-bottom: 1px solid #dee2e6;
}

.main .card .print-table .table-row .table-col-1 {
  width: 10%;
}

.main .card .print-table .table-row .table-col-9 {
  width: 90%;
}

.main .card .print-table .table-row .col-12 {
  background-color: #f7f7f7;
  line-height: 40px;
  font-size: small;
}

.main .card .print-table .table-row:hover {
  background-color: #f7f7f7;
}

.main .card .print-table .table-row:hover .form-control {
  background-color: #fff;
}