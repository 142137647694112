.active-nav-link span {
  font-weight: 500;
  color: black;
}

.admin-drop-down:hover span {
  font-weight: 600;
}

.header .dropdown-toggle::after {
  display: none;
}
